<template>
  <div>
    <div class="sm:pb-3">
      <div v-for="(field, index) in fields" :key="index" class="col-span-4">
        <!--              If input is email-->
        <div v-if="field.key === 'email'">
          <ValidationProvider
            v-slot="{ errors }"
            :class="list ? '' : 'sm:grid sm:grid-cols-3 sm:gap-4 border-t'"
            class="mt-0"
            name="email"
            rules="required|email"
          >
            <div class="text-sm leading-5 sm:col-span-2">
              <label :for="field.kind" class="font-normal text-gray-700">
                {{ $t(field.label) }}</label
              >
              <span class="text-sm text-red-600 h-4"> * </span>
            </div>
            <div class="mt-1 rounded-md shadow-sm sm:col-span-2">
              <input
                :id="field.id"
                v-model="form[field.key]"
                :placeholder="
                  field.placeholder ? field.placeholder : $t(field.label)
                "
                :type="field.key"
                class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
            <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <!--              If input is sort_code-->

        <div
          v-if="field.key === 'sort_code' && form.location === 10"
          class="py-2"
        >
          <ValidationProvider
            v-slot="{ errors }"
            name="string"
            rules="required"
          >
            <div class="text-sm leading-5">
              <label :for="field.kind" class="font-normal text-gray-700">{{
                $t(field.label)
              }}</label>
              <span class="text-sm text-red-600 h-4"> * </span>
            </div>
            <div class="mt-1 rounded-md shadow-sm">
              <input
                :id="field.id"
                v-model="form[field.key]"
                :placeholder="$t(field.label)"
                :type="field.key"
                class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
            <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <!--              If input is account_holder_name-->

        <div v-if="field.key === 'account_holdername'" class="py-2">
          <ValidationProvider
            v-slot="{ errors }"
            name="string"
            rules="required"
          >
            <div class="text-sm leading-5">
              <label :for="field.kind" class="font-normal text-gray-700">{{
                $t("beneficiary-name-account-holder")
              }}</label>
              <span class="text-sm text-red-600 h-4"> * </span>
            </div>
            <div class="mt-1 rounded-md shadow-sm">
              <input
                :id="field.id"
                v-model="form[field.key]"
                :placeholder="$t('account-holder-name')"
                :type="field.key"
                class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
            <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <!--              If input is IBAN-->

        <div v-if="field.key === 'iban' && form.location === 1" class="py-2">
          <ValidationProvider
            v-slot="{ errors }"
            name="string"
            rules="required"
          >
            <div class="text-sm leading-5 pb-2">
              <label :for="field.kind" class="font-normal text-gray-700">{{
                $t("IBAN")
              }}</label>
              <span class="text-sm text-red-600 h-4"> * </span>
            </div>
            <div class="mt-1 rounded-md shadow-sm">
              <input
                :id="field.id"
                v-model="form[field.key]"
                :placeholder="$t('IBAN')"
                :type="field.key"
                class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
            <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <!--              If input is swift-->

        <div
          v-if="
            field.key === 'swift_code' &&
            (form.location === 0 || form.location === 1)
          "
          class="py-2"
        >
          <ValidationProvider
            v-slot="{ errors }"
            name="string"
            rules="required"
          >
            <div class="text-sm leading-5 pb-2">
              <label :for="field.kind" class="font-normal text-gray-700">{{
                $t("bic-swift")
              }}</label>
              <span class="text-sm text-red-600 h-4"> * </span>
            </div>
            <div class="mt-1 rounded-md shadow-sm">
              <input
                :id="field.id"
                v-model="form[field.key]"
                :placeholder="$t('bic-swift')"
                :type="field.key"
                class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
            <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
            <span
              v-if="error.status && field.key === 'swift_code'"
              class="text-sm text-red-600 h-4"
              >{{ error.message }}</span
            >
          </ValidationProvider>
        </div>

        <!--              If input is routing_number-->

        <div
          v-if="
            (field.key === 'routing_number' && form.location === 20) ||
            (field.key === 'routing_number' && form.location === 20)
          "
          class="py-2"
        >
          <ValidationProvider
            v-slot="{ errors }"
            name="string"
            rules="required"
          >
            <div class="text-sm leading-5 py-2">
              <label :for="field.kind" class="font-normal text-gray-700">{{
                field.label
              }}</label>
              <span class="text-sm text-red-600 h-4"> * </span>
            </div>
            <div class="mt-1 rounded-md shadow-sm">
              <input
                :id="field.id"
                v-model="form[field.key]"
                :placeholder="$t(field.label)"
                :type="field.key"
                class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
            <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <!--              If input is amount-->

        <div
          v-if="field.key === 'amount'"
          :class="list ? '' : 'border-t '"
          class="py-2"
        >
          <div class="mt-0 sm:grid sm:grid-cols-8">
            <dt class="text-sm leading-5 sm:col-span-4 font-normal">
              {{ $t(field.label) }}
              <span
                v-if="field.needed || field.needed === undefined"
                class="text-sm text-red-600 h-4"
              >
                *
              </span>
            </dt>
            <dd class="mt-1 text-sm leading-5 text-gray-900 mt-0 sm:col-span-4">
              <div class="sm:col-span-4">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: field.needed }"
                  name="amount"
                >
                  <div class="mt-1 relative rounded-md shadow-sm input">
                    <div
                      class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                    >
                      <span
                        v-if="form.currency"
                        class="text-gray-300 sm:text-md sm:leading-5"
                        v-html="currencySymbol(form.currency)"
                      >
                      </span>
                    </div>
                    <input
                      id="amount"
                      v-model="form[field.key]"
                      aria-describedby="price-currency"
                      class="form-input h-12 font-light block w-full pl-7 pr-3 sm:text-sm sm:leading-5"
                      type="number"
                    />
                  </div>
                  <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </dd>
          </div>
        </div>

        <!--              If input is amount in words-->

        <div v-if="field.key === 'amount_in_words' && form.amount">
          <div class="text-sm leading-5 border-t py-2">
            <label :for="field.kind" class="font-normal text-gray-700">
              {{ $t("amount-in-words") }}</label
            >
          </div>
          <div class="font-normal text-gray-500 text-sm">
            {{ form.amount | toWords }}
          </div>
        </div>

        <!--              If input is select menu-->
        <div
          v-if="
            field.choices &&
            field.key !== 'trading_accounts' &&
            field.key !== 'currency'
          "
          class="py-2"
        >
          <ValidationProvider
            v-slot="{ errors }"
            :class="list ? '' : ' sm:grid sm:grid-cols-8 border-t'"
            :name="field.key"
            :rules="{ required: field.needed }"
          >
            <div class="text-sm leading-5 sm:col-span-4">
              <label :for="field.kind" class="font-normal text-gray-700">
                {{ $t(field.label) }}
              </label>
              <span
                v-if="field.needed || field.needed === undefined"
                class="text-sm text-red-600 h-4"
              >
                *
              </span>
            </div>
            <div class="sm:col-span-3">
              <v-select
                v-model="form[field.key]"
                :clearable="false"
                :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                :options="field.choices"
                :placeholder="
                  $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                "
                :reduce="(item) => item.value"
                class="style-chooser"
                label="display_name"
              ></v-select>
              <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>

        <div
          v-if="
            field.choices &&
            field.key !== 'trading_accounts' &&
            field.key === 'currency' &&
            bank
          "
          class="py-2"
        >
          <ValidationProvider
            v-slot="{ errors }"
            :class="list ? '' : 'py-2 sm:grid sm:grid-cols-8 border-t'"
            :name="field.key"
            :rules="{ required: field.needed }"
          >
            <div class="text-sm leading-5 sm:col-span-4">
              <label :for="field.kind" class="font-normal text-gray-700">
                {{ $t(field.label) }}
              </label>
              <span
                v-if="field.needed || field.needed === undefined"
                class="text-sm text-red-600 h-4"
              >
                *
              </span>
            </div>
            <div class="sm:col-span-3">
              <v-select
                v-model="form[field.key]"
                :clearable="false"
                :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                :options="field.choices"
                :placeholder="
                  $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                "
                :reduce="(item) => item.value"
                class="style-chooser"
                label="display_name"
              ></v-select>
              <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>

        <div
          v-if="
            field.choices &&
            field.key === 'currency' &&
            (form.location === 0 || form.location === 10 || form.location === 1)
          "
          class="py-2"
        >
          <ValidationProvider
            v-slot="{ errors }"
            :class="list ? '' : ' sm:grid sm:grid-cols-8 border-t'"
            :name="field.key"
            :rules="{ required: field.needed }"
          >
            <div class="text-sm leading-5 sm:col-span-4">
              <label :for="field.kind" class="font-normal text-gray-700">
                {{ $t("bank-account-currency") }}
              </label>
              <span
                v-if="field.needed || field.needed === undefined"
                class="text-sm text-red-600 h-4"
              >
                *
              </span>
            </div>
            <div class="sm:col-span-3">
              <v-select
                v-model="form[field.key]"
                :clearable="false"
                :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                :options="field.choices"
                :placeholder="
                  $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                "
                :reduce="(item) => item.value"
                class="style-chooser"
                label="display_name"
              ></v-select>
              <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>
        <!--              If input is checkbox-->

        <div v-if="field.kind === 'checkbox'" class="py-2">
          <ValidationProvider
            v-slot="{ errors }"
            :name="field.key"
            :rules="{ required: field.needed }"
          >
            <div class="relative flex items-start border-t">
              <div class="flex items-center h-5">
                <input
                  :id="field.key"
                  v-model="form[field.key]"
                  class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  type="checkbox"
                  @input="updateForm(form, $event.target.value)"
                />
              </div>
              <div class="ml-3 text-sm leading-5">
                <label
                  :for="field.key"
                  class="font-normal text-gray-700"
                  @input="$event, field.label"
                >
                  {{
                    field.label
                      .replace("%first_name%", form.first_name)
                      .replace("%last_name%", form.last_name)
                      .replace("{}", form.first_name + " " + form.last_name)
                  }}
                </label>
                <span
                  v-if="field.needed || field.needed === undefined"
                  class="text-sm text-red-600 h-4"
                >
                  *
                </span>
              </div>
            </div>
            <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <!--              If input is radio btn-->

        <div v-if="field.kind === 'radio'" class="py-2">
          <ValidationProvider
            v-slot="{ errors }"
            :name="field.key"
            :rules="{ required: field.needed }"
          >
            <div class="text-sm mb-4 border-t">
              <label :for="field.key" class="font-normal text-gray-700">{{
                field.label
              }}</label>
              <br />
              <label :for="field.key" class="font-normal text-gray-500">{{
                field.description
              }}</label>
              <span
                v-if="field.needed || field.needed === undefined"
                class="text-sm text-red-600 h-4"
              >
                *
              </span>
            </div>
            <div
              v-for="(choice, index) in field.choices"
              :key="index"
              class="flex items-center"
            >
              <input
                :id="choice.value"
                v-model="form[field.key]"
                :name="field.key"
                :value="choice.value"
                class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                type="radio"
              />
              <label :for="choice.value" class="ml-3">
                <span
                  class="block text-sm leading-5 font-normal text-gray-700"
                  >{{ choice.display_name }}</span
                >
                <span
                  class="block text-sm leading-5 font-normal text-gray-700"
                  >{{ choice.description }}</span
                >
              </label>
            </div>
            <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <!--              bank city-->
        <div
          v-if="
            field.key === 'bank_city' &&
            (form.location === 50 || form.location === 1 || form.location === 0)
          "
          class="py-2"
        >
          <ValidationProvider
            v-slot="{ errors }"
            :class="
              list
                ? ''
                : 'sm:grid sm:grid-cols-8 border-t' && bank
                ? 'py-2 sm:grid sm:grid-cols-8 border-t'
                : ''
            "
            :name="field.key"
            :rules="{ required: field.needed }"
          >
            <label
              :for="field.id"
              class="text-sm font-normal sm:col-span-4 leading-5 text-gray-700"
            >
              {{ $t("bank-city") }}
            </label>
            <span
              v-if="field.needed || field.needed === undefined"
              class="text-sm text-red-600 h-4"
            >
              *
            </span>
            <div class="mt-1 rounded-md sm:col-span-4">
              <input
                :id="field.id"
                v-model="form[field.key]"
                :placeholder="$t('bank-city')"
                :type="field.kind"
                class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
              <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>

        <div
          v-if="field.key === 'account_branch' && form.location === 50"
          class="py-2"
        >
          <ValidationProvider
            v-slot="{ errors }"
            :class="
              list
                ? ''
                : 'sm:grid sm:grid-cols-8 border-t' && bank
                ? 'py-2 sm:grid sm:grid-cols-8 border-t'
                : ''
            "
            :name="field.key"
            :rules="{ required: field.needed }"
          >
            <label
              :for="field.id"
              class="text-sm font-normal sm:col-span-4 leading-5 text-gray-700"
            >
              {{ $t("account-branch") }}
            </label>
            <span
              v-if="field.needed || field.needed === undefined"
              class="text-sm text-red-600 h-4"
            >
              *
            </span>
            <div class="mt-1 rounded-md sm:col-span-4">
              <input
                :id="field.id"
                v-model="form[field.key]"
                :placeholder="$t('bank-city')"
                :type="field.kind"
                class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
              <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>

        <div
          v-if="field.key === 'bank_state' && form.location === 50"
          class="py-2"
        >
          <ValidationProvider
            v-slot="{ errors }"
            :class="
              list
                ? ''
                : 'sm:grid sm:grid-cols-8 border-t' && bank
                ? 'py-2 sm:grid sm:grid-cols-8 border-t'
                : ''
            "
            :name="field.key"
            :rules="{ required: field.needed }"
          >
            <label
              :for="field.id"
              class="text-sm font-normal sm:col-span-4 leading-5 text-gray-700"
            >
              {{ $t("province-of-the-branch") }}
            </label>
            <span
              v-if="field.needed || field.needed === undefined"
              class="text-sm text-red-600 h-4"
            >
              *
            </span>
            <div class="mt-1 rounded-md sm:col-span-4">
              <input
                :id="field.id"
                v-model="form[field.key]"
                :placeholder="$t('bank-state')"
                :type="field.kind"
                class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
              <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>

        <!--              Other inputs-->
        <div
          v-if="
            !field.choices &&
            field.type !== 'checkbox' &&
            field.type !== 'email' &&
            field.key !== 'sort_code' &&
            field.key !== 'swift_code' &&
            field.key !== 'routing_number' &&
            field.type !== 'radio' &&
            field.key !== 'bank_city' &&
            field.key !== 'account_branch' &&
            field.key !== 'bank_state' &&
            field.key !== 'bank_state' &&
            field.key !== 'amount' &&
            field.key !== 'iban' &&
            field.key !== 'account_holdername' &&
            field.key !== 'amount_in_words'
          "
          class="py-2"
        >
          <ValidationProvider
            v-slot="{ errors }"
            :class="
              list
                ? ''
                : 'sm:grid sm:grid-cols-8 border-t' && bank
                ? 'py-2 sm:grid sm:grid-cols-8 border-t'
                : ''
            "
            :name="field.key"
            :rules="{ required: field.needed }"
          >
            <label
              :for="field.id"
              class="text-sm font-normal sm:col-span-4 leading-5 text-gray-700"
            >
              {{ $t(field.key.replace("_", "-").replace("_", "-")) }}
              <span
                v-if="field.needed || field.needed === undefined"
                class="text-sm text-red-600 h-4"
              >
                *
              </span>
            </label>
            <div class="mt-1 rounded-md sm:col-span-4">
              <input
                :id="field.id"
                v-model="form[field.key]"
                :placeholder="$t(field.key.replace('_', '-').replace('_', '-'))"
                :type="field.kind"
                class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
              <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BankDetailsForms",
  data() {
    return {
      languageDirection: "",
    };
  },
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    fields: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    bank: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    list: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    error: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    // on select forms, we need to set dir = 'rtl' instead of default dir='auto'
    // so non translated text would show the corect way on arab and persian pages
    checkLanguageDirection() {
      this.languageDirection = this.$store.getters.get_lang;
      if (this.lang === "ar" || this.lang === "fa") {
        return document.getElementById("body").dir;
      } else {
        return document.getElementById("body").dir;
      }
    },
  },
};
</script>